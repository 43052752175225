<template>
     <div id="course-view">
        <v-row>
            <v-col
                cols="12"
                md="8"
                lg="8"
            >
                <course-detail-panel
                    :courseData="courseData"
                    :video-src="src"
                ></course-detail-panel>
            </v-col>
            <v-col
                cols="12"
                md="4"
                lg="4">
                <course-detail-content
                    :courseData="courseData"
                    @updateSrc="updateSrc"
                ></course-detail-content>
            </v-col>
        </v-row>
        <v-row v-if="courseData">
            <v-col
                cols="12"
                sm="12">
                <v-card>
                    <v-card-text>
                        <v-data-table
                            :headers="studentHeaders"
                            :items="courseData.students"
                            dense
                        >
                        <template v-slot:top>
                            <v-toolbar
                                flat
                            >
                                <v-toolbar-title>Graduates</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-dialog
                                v-model="dialog"
                                max-width="500px"
                                v-if="instructorPermission"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    Add Graduates
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                    <span class="text-h5">Add Students</span>
                                    </v-card-title>

                                    <v-card-text>
                                    <v-container>
                                        <v-row>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="12"
                                        >
                                            <v-autocomplete
                                                v-model="students"
                                                :loading="userLoading"
                                                :items="filterNonStudent"
                                                :search-input.sync="NonStudentSearch"
                                                item-text="name"
                                                item-value="azure_ad_id"
                                                label="Students"
                                                persistent-hint
                                                outlined
                                                dense
                                                multiple
                                                small-chips
                                                deletable-chips
                                                placeholder="Students"
                                                @change="NonStudentSearch=''"
                                                :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                                                >
                                                <!-- Options Slot -->
                                                <template #item="{ item }">
                                                    <div class="d-flex align-center">
                                                    <v-avatar
                                                            :color="stringToHslColor(item.name) || primary"
                                                            size="25"
                                                            class="me-2"
                                                        >
                                                            <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                                                        </v-avatar>
                                                    <span class="text-sm">{{ item.name }}</span>
                                                    </div>
                                                </template>
                                            ></v-autocomplete>
                                        </v-col>
                                        
                                        </v-row>
                                    </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        @click="close"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        :loading="studentsToCompletedLoading"
                                        @click="addStudents"
                                    >
                                        Add
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                                </v-dialog>
                                
                            </v-toolbar>
                            </template>

                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch, getCurrentInstance } from '@vue/composition-api'
import academyStoreModule from '../academyStoreModule'
import store from '@/store'
import useCourse from '../useCourse'
import router from '@/router'
import { stringToHslColor } from '@core/utils'
import { avatarText } from '@core/utils/filter'

import CourseDetailPanel from './course-detail-panel/CourseDetailPanel.vue'
import CourseDetailContent from './course-detail-content/CourseDetailContent.vue'

export default ({
    components:{
        CourseDetailPanel,
        CourseDetailContent
    },
    setup() {
        const vm = getCurrentInstance().proxy
        const instructorPermission = vm.$ability.can('add', 'academy')
        const ACADEMY_APP_STORE_MODULE_NAME = 'app-academy'
        // Register module
        if (!store.hasModule(ACADEMY_APP_STORE_MODULE_NAME)) store.registerModule(ACADEMY_APP_STORE_MODULE_NAME, academyStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
        if (store.hasModule(ACADEMY_APP_STORE_MODULE_NAME)) store.unregisterModule(ACADEMY_APP_STORE_MODULE_NAME)
        })

        const {
            courseData,
            userLoading,
            userOptions,
            studentsToCompletedLoading,

            fetchUsers,
            fetchCourse,
            addStudentsToCompletedCourse
        } = useCourse()

        const students = ref([])
        const NonStudentSearch = ref('')

        const dialog = ref(false)

        const close = () => {
            dialog.value = false
            students.value = []
        }

        const src = ref('')
        const updateSrc = (fileSrc) => {
            src.value = fileSrc
        }

        const addStudents = () => {
            let completed = []
            students.value.map(student => {
                const course = {
                    course:courseData.value.course_id,
                    user: student
                }
                completed.push(course)
            })
            addStudentsToCompletedCourse(completed)
            

        }

        const studentHeaders = [
        {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'user__name',
          },
          { text: 'Email', value: 'user__email' }
      ]



        onMounted(() => {
            fetchCourse(router.currentRoute.params.slug)

        })

        const filterNonStudent = computed(() => {
            return userOptions.value.filter(user => !courseData.value.students.some(student => user.azure_ad_id === student.user__azure_ad_id))
        })

        watch(dialog,
            (newVal, oldVal) => {
                const studentList = JSON.parse(JSON.stringify(userOptions.value))
                if (newVal && studentList.length == 0) fetchUsers()
                if (!newVal) students.value = []
            }
        )

        watch(
            studentsToCompletedLoading,
            (newVal, oldVal) => {
                if(oldVal) {
                    dialog.value = false
                    fetchCourse(router.currentRoute.params.slug)
                }
            }
        )

        return {
            courseData,
            studentHeaders,
            studentsToCompletedLoading,
            instructorPermission,
            dialog,
            close,
            students,
            userLoading,
            NonStudentSearch,
            filterNonStudent,
            avatarText,
            stringToHslColor,
            addStudents,
            updateSrc,
            src
        }
    },
})
</script>
